import './Title.scss';

const Title = ({ title, uppercase }) => {
    return (
        <div className="title_katana__area" style={{textTransform: uppercase ? 'uppercase' : 'none'}}>
            {title}
        </div>
    );
}

export default Title;