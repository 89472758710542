import './SectionKatana.scss';
import { Container, Title } from '../index';
import { Row, Col } from 'antd';

const SectionKatana = () => {
    return (
        <section id="section_katana" className="section_katana" data-aos="zoom-in">
            <Container>
                <Row gutter={50} align="middle">
                    <Col xs={24} lg={12}>
                        <div className="title">
                            <Title title="KATANA Makuri E7 10W-40" />
                        </div>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="description">
                            Synthetic motor oil of SHPD class, ideally suited for highly loaded diesel engines of modern trucks, buses, and special vehicles. It provides reliable protection and high performance.
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SectionKatana;