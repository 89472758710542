import './Header.scss';
import { Menu, ConfigProvider } from 'antd';
import { itemsMenu } from './data';
import { Container } from '../index';
import logo from '../../images/logo.png';

const themeConfig = {
    components: {
        Menu: {
            darkItemSelectedBg: 'none',
            horizontalItemHoverColor: 'red',
            horizontalItemSelectedColor: 'red',
            darkItemHoverColor: '#b0852e',
            darkItemColor: '#fff',
            groupTitleFontSize: '20px',
            fontSize: '20px'
        },
    },
};

const Header = () => {
    return (
        <ConfigProvider theme={themeConfig}>
            <div className="katana__menu">
                <Container>
                    <div className='header__logo-menu'>
                        <img src={logo} alt="Logo" className='header__logo' />
                        <Menu mode='horizontal' className='header__nav' theme='dark'>
                            {itemsMenu.map(item => (
                                <Menu.Item key={item.key} onClick={() => item.link && (window.location.href = item.link)}>
                                    {item.label}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                </Container>
            </div>
        </ConfigProvider>
    );
}

export default Header;
