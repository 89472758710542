import './SectionAbout.scss';
import { Container, Title } from '../index';
import { Row, Col } from 'antd';
import { useIsMobile } from '../../utils/useIsMobile';
import oil2 from '../../images/bg__about.webp';
import logo from '../../images/logo.png';

const SectionAbout = () => {
    const isTablet = useIsMobile(1200);
    return (
        <section id="section_about" className="section_about" data-aos="zoom-in">
            <Container>
                <Row align='middle'>
                    <Col xs={24} xl={10}>
                        <div className="about__logo">
                            <img src={logo} alt="" />
                        </div>
                        <div className="about__content">
                            <div className="title">
                                <Title title="About" />
                            </div>
                            <div className="description">
                                We are changing the world's perception of motor oils
                            </div>
                            <div className="more">
                                Modern developments in synthetic and semi-synthetic oils provide improved protection and performance for engines, which can completely change the perception of motor oils. It is important for consumers to be aware of the advantages of new technologies.
                            </div>
                        </div>
                    </Col>
                    <Col span={14} className={isTablet ? 'bg__oil' : ''}>
                        <img src={oil2} alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SectionAbout;