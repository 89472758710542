const itemsMenu = [
    {
        label: 'About',
        key: 'about',
        link: '#section_about'
    },
    {
        label: 'KATANA Makuri E7 10W-40',
        key: 'katana',
        link: '#section_katana'
    },
    {
        label: 'Advantages',
        key: 'advantages',
        link: '#section_advantages'
    },
    {
        label: 'Technologies',
        key: 'technologies',
        link: '#section_technologies'
    },
    {
        label: 'Products',
        key: 'products',
        link: '#section_products'
    }
]

export {
    itemsMenu
}