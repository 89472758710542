import './App.css';

import TitlePage from './pages/title/TitlePage';

function App() {
    return (
        <div className="App">
                <TitlePage />
        </div>
    );
}

export default App;
