import './SectorAdvantages.scss';
import CountUp from 'react-countup';
import React, { useRef, useState, useEffect } from 'react';
import { Container, Title } from '../index';
import { Row, Col } from 'antd';

const SectorAdvantages = () => {
    const countersRef = useRef([]);
    const [startCount, setStartCount] = useState([false, false, false]);

    useEffect(() => {
        countersRef.current.forEach((ref, index) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        setStartCount((prev) => {
                            const newStartCount = [...prev];
                            newStartCount[index] = true;
                            return newStartCount;
                        });
                        observer.unobserve(ref);
                    }
                },
                {
                    threshold: 0.2,
                }
            );
            if (ref) observer.observe(ref);
        });
    }, []);

    return (
        <section id="section_advantages" className="section_advantages" data-aos="zoom-in">
            <Container>
                <div className="title">
                    <Title title="Main technical advantages" uppercase />
                </div>
                <Row>
                    <Col xs={20} lg={12} style={{ background: "rgba(57, 47, 47, .7)", padding: '20px' }}>
                        <div className="count" ref={(el) => countersRef.current[0] = el}>
                            {startCount[0] ? <CountUp end={35} duration={5} /> : '0'}%
                        </div>
                        <div className="description">
                            Increase of oil service resource
                        </div>
                        <div className="more">
                            The KATANA Makuri E7 10W-40 motor oil features an increased alkaline reserve that exceeds standard ACEA E7 (API CI-4) oils by 30-40%. This allows for extended oil change intervals and provides better compatibility with high-sulfur fuel (up to 5000 ppm).
                        </div>
                    </Col>
                    <Col xs={4} lg={12}></Col>
                </Row>
                <Row>
                    <Col xs={4} lg={12}></Col>
                    <Col xs={20} lg={12} style={{ background: "rgba(57, 47, 47, .7)", padding: '20px' }}>
                        <div className="count" ref={(el) => countersRef.current[1] = el}>
                            {startCount[1] ? <CountUp end={40} duration={5} /> : '0'}%
                        </div>
                        <div className="description">
                            Reduction of oil top-up volume
                        </div>
                        <div className="more">
                            Thanks to the use of synthetic base oils with low volatility (NOACK test at 250°C), KATANA Makuri E7 10W-40 engine oil is characterized by minimal oil consumption. This significantly reduces the need for top-ups, potentially saving between 2 to 5 liters, depending on operating conditions and service intervals. As a result, users can enjoy an economic benefit of 5-12% from its application.
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={20} lg={12} style={{ background: "rgba(57, 47, 47, .7)", padding: '20px' }}>
                        <div className="count" ref={(el) => countersRef.current[2] = el}>
                            {startCount[2] ? <CountUp end={45} duration={5} /> : '0'}%
                        </div>
                        <div className="description">
                            Improvement of low-temperature pumpability of oil
                        </div>
                        <div className="more">
                            The content of synthetic base oils in KATANA Makuri E7 10W-40 exceeds that of traditional semi-synthetic oils of class 10W-40 by 10-15%, ensuring outstanding low-temperature performance (pumpability in the MRV test and cranking ability in the CCS test). As a result, this oil approaches the characteristics of synthetic oils in the 5W-40 class.
                        </div>
                    </Col>
                    <Col xs={4} lg={12}></Col>
                </Row>
            </Container>
        </section>
    );
}

export default SectorAdvantages;
