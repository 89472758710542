import { ReactComponent as Icon_1 } from '../../images/icons/item_1.svg';
import { ReactComponent as Icon_2 } from '../../images/icons/item_2.svg';
import { ReactComponent as Icon_3 } from '../../images/icons/item_3.svg';
import { ReactComponent as Icon_4 } from '../../images/icons/item_4.svg';
import { ReactComponent as Icon_5 } from '../../images/icons/item_5.svg';
import { ReactComponent as Icon_6 } from '../../images/icons/item_6.svg';


const itemsTechnologies = [
    {
        label: 'EvaLock',
        key: 'evaLock',
        description: 'The unique combination of components reduces evaporation loss at high temperatures',
        icon: <Icon_1 />
    },
    {
        label: 'OxiLock',
        key: 'oxiLock',
        description: 'High oxidation stability extends oil life even under poor fuel conditions',
        icon: <Icon_2 />
    },
    {
        label: 'WearShield',
        key: 'wearshield',
        description: 'Superior engine wear protection even at highly loaded application',
        icon: <Icon_3 />
    },
    {
        label: 'StabiVis',
        key: 'stabivis',
        description: 'High shear stability to protect oil pressure during long application mode',
        icon: <Icon_4 />
    },
    {
        label: 'FlowGuard',
        key: 'flowguard',
        description: 'Esy engine start up at extremely low temperatures',
        icon: <Icon_5 />
    },
    {
        label: 'UltraSynth',
        key: 'ultrasynth',
        description: 'The best synhetic base oils technology (PAO, GTL, 3+)',
        icon: <Icon_6 />
    },
]

export {
    itemsTechnologies
}