import './TitlePage.scss';
import React, { useEffect } from 'react';
import {itemsProducts, itemsFooter } from './data';
import {
    Container,
    Header, Title,
    SectionMain,
    SectionAbout,
    SectionKatana,
    SectorAdvantages,
    SectorTechnologies
} from '../../components/index';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../../images/logo.png';

const TitlePage = () => {

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);


    return (
        <div className='title__area'>
            <Header />
            <SectionMain />
            <SectionAbout />
            <SectionKatana />
            <SectorAdvantages />
            <SectorTechnologies />
            <section id="section_products" className="section_products" data-aos="zoom-in">
                <Container>
                    <div className="title">
                        <Title title="KATANA Products" />
                    </div>
                    <div className="all_products">All Products</div>
                    <div className="products__grid">
                        {itemsProducts.map((product) => {
                            return (
                                <div className="product">
                                    <img src={product.images} alt="" />
                                    <div className="title">{product.label}</div>
                                </div>
                            )
                        })
                        }
                    </div>
                </Container>
            </section>
            <section id="section_footer" className="section_footer">
                <Container>
                    <Title title="Business Info" />
                    <img src={logo} alt="" />
                    {itemsFooter.map((item) => {
                        return (
                            <>
                                {item.icon}
                                {item.label}
                            </>
                        )
                    })}
                </Container>
            </section>
        </div >

    );
}

export default TitlePage;