import './SectorTechnologies.scss';
import {Container, Title} from '../index';
import {Row, Col} from 'antd';
import oil from '../../images/title__oil.webp';
import {itemsTechnologies} from './data';

const SectorTechnologies = () => {
    return (
        <section id="section_technologies" className="section_technologies">
            <Container>
                <div className="title">
                    <Title title="Meet our technologies" uppercase />
                </div>
                <Row align="middle">
                    <Col span={12}>
                        {itemsTechnologies.map((item) => {
                            return (
                                <div className="content">
                                    <div className="title_technologies__area">
                                        <div className="icon">{item.icon}</div>
                                        <div className="title">{item.label}</div>
                                    </div>
                                    <div className="description" data-aos="fade-right">{item.description}</div>
                                </div>
                            )
                        })}
                    </Col>
                    <Col span={12}>
                        <div className="oil">
                            <img src={oil} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SectorTechnologies;